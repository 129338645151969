@import 'variables';
@import 'overrides';
@import 'wrapper';
@import 'masthead';
@import 'hero';
@import 'social';
@import 'module';
@import 'footer';
@import 'photos';

.no-wrap {
  white-space: nowrap;
}

.clear {
  clear: both;
}

.prose {
  font-size: 16px;
  line-height: 26px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $black;
  }
}

.prose ul {
  margin-left: 1.2em;
  list-style-type: square;
}

.prose ol {
  margin-left: 1.3em;
  list-style-type: decimal;
}

.prose .heading {
  margin-top: 24px;
  margin-bottom: 0;
}

.prose img {
  margin-top: 8px;
}

.prose .image {
  border: none;
}

.prose .bordered {
  padding: 4px;
  border: 1px solid $grey-light;
}

.prose .bordered:hover {
  border-color: $tertiary;
}

.bordered {
  display: inline-block;
  padding: 4px;
  border: 1px solid $grey-light;
}

.bordered img {
  margin: 0;
}

.lanyrd-link {
  position: relative;
  top: -4px;
  left: 0px;
  display: inline-block;
  width: 32px;
  height: 32px;
  text-indent: -900em;
  background: url(../img/lanyrd-48x48.png) no-repeat center center;
  background-size: 16px 16px;
  z-index: 1;
  margin-bottom: -8px;
  vertical-align: text-top;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 16px;

  &::before,
  &::after {
    content: '';
    display: table;
    width: 0;
    height: 0;
    clear: both;
  }
}

.event-detail .lanyrd-link {
  top: 7px;
  left: 5px;
  width: 32px;
  height: 32px;
  background-size: 20px 20px;
}

.event-detail .meta {
  font-size: 18px;
}

.event-detail .description::before,
.event-detail .description::after {
  content: '';
  display: table;
  clear: both;
}

.event-detail .bordered {
  margin-bottom: 8px;
}

.event-detail .vevent {
  font-size: 16px;
  line-height: 20px;
  position: relative;
  padding: 14px 0 12px 58px;
  min-height: 40px;
  border-left: 0;
  border-right: 0;
  margin: 8px 0;
}

.event-detail .vevent .date {
  margin-top: -2px;
  display: block;
}

.event-detail .vevent .adr {
  display: none;
}

.event-detail .vevent .map {
  position: absolute;
  top: 8px;
  left: 0px;
  display: block;
}

.event-detail .vevent .map img {
  max-width: 40px;
  max-height: 40px;
}

.event-detail .sponsors {
  position: absolute;
  right: 0;
  top: 7px;
  position: static;
  overflow: hidden;
  margin-left: -58px;
  margin-top: 13px;
  padding-top: 8px;
}

.event-detail .sponsor-logo {
  float: right;
  float: none;
}

.event-detail .sponsor-logo img {
  display: block;
  max-height: 52px;
  max-width: auto;
}

.event-detail .description.image .bordered {
  float: left;
  width: 40%;
  max-width: 300px;
  margin: 4px 12px 12px 0;
}

.event-item {
  margin: 0 0 16px 0;

  h3,
  p {
    margin: 4px 0;
  }
}

.event-item .lanyrd-simple {
  font-size: 13px;
  line-height: 1;
  color: #2e2e2e;
}

.news {
  margin-top: -20px;
}

.news:first-of-type {
  margin-top: 0;
}

.news:after {
  content: "";
  margin: 0;
}

.news a {
  display: block;
  padding: 8px 0;
  height: auto;
  line-height: 1;
}

.news:hover a {
  color: $black;
  border-color: $grey-medium;
}

.news strong {
  font-weight: normal;
}

.news-item .read-more {
  margin-top: -16px;
}

.dharmafly {
  text-decoration: none;
  padding: 1px;
  font-size: 11px;
}

.dharmafly:hover,
.dharmafly:focus {
  color: $green-light;
}

.dharmafly:active {
  color: $white;
  background-color: $green-light;
  border-bottom: none;
}

.extra .heading {
  margin-bottom: 8px;
  margin-top: 0;
}

.lanyrd-people {
  list-style-type: none;
  overflow: hidden;
  position: relative;
  left: -8px;
  margin-bottom: 0;
}

.lanyrd-people {
  margin-bottom: 8px;
}

.lanyrd-people li {
  float: left;
  margin-left: 8px;
}

.lanyrd-people li a {
  width: 50px;
}

.lanyrd-trackers li a {
  width: 35px;
}

.hubbub {
  margin-top: 24px;
}

.hubbub-avatar {
  width: 40px;
}

.hubbub-avatar-link {
  float: left;
  margin-top: 4px;
}

.hubbub-content {
  margin-left: 60px;
}

.hubbub-timestamp {
  float: right;
}

.hubbub-header a {
  margin-right: 5px;
}

.hubbub-comment-body p {
  margin-top: 8px;
  margin-bottom: 16px;
}

.iframe-wrapper {
  position: relative;
  padding-bottom: 65%;
  margin-bottom: 20px;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  max-width: 100%;
}

.iframe-slides .iframe {
  width: 200%;
  height: 200%;
  max-width: 200%;
  transform: scale(0.62);
  transform-origin: 0;
}

#media-test {
  display: none;
  visibility: hidden;
  width: 0;
}

@media (min-width: $screen-xs) {
  h2 {
    font-size: 36px;
  }

  .event-detail .description.image {
    position: relative;
  }

  .event-detail .description.image .bordered {
    float: right;
    margin: 4px 0 12px 12px;
  }

  #media-test {
    width: 1px;
  }

  .event-detail .sponsors {
    position: absolute;
    right: 0;
    top: 7px;
    border-top: none;
    padding: 0;
    margin: 0;
  }

  .event-detail .sponsors .sponsor-logo {
    float: right;
  }
}

@media (min-width: $screen-md) {
  .column {
    float: left;
    box-sizing: border-box;
    width: 45%;
    margin-right: 5%;
  }

  .column:nth-child(odd) {
    clear: left;
  }

  .column:after {
    content: "";
  }
}

body,
.masthead p em {
  font-family: $font-family-body;
  font-weight: $font-weight-regular;
}

i,
em {
  font-family: $font-family-body;
}

b, strong,
.read-more a,
.event-detail .vevent,
.event-item .lanyrd-simple {
  font-family: $font-family-body;
  font-weight: $font-weight-bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
.header,
.news strong {
  font-family: $font-family-header;
  font-weight: $font-weight-regular;
  text-rendering: optimizeLegibility; // May cause issues in Windows browsers
}
