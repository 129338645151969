@import 'variables';

.photo-grid {
  &::before,
  &::after {
    // Clearfix for float
    content: '';
    display: table;
    clear: both;
  }

  img {
    float: left;
    width: 50%;

    @media (min-width: $screen-md) {
      width: 25%;
    }
  }
}
