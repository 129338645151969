$screen-xs: 480px;
$screen-sm: 560px;
$screen-md: 768px;

$white: #fff;
$grey-light: #ccc;
$grey-medium: #aaa;
$black: #333;

$primary: #b144ab;
$secondary: #e97223;
$tertiary: #0da1b8;
$success: #2da73c;
$error: #e35050;

$primary-dark: darken($primary, 30%);

$meetup-color: #F64060;

$green-light: #a6c664;

$social-icon-size: 32px;
$masthead-height: 70px;
$footer-height: 250px;

$font-weight-regular: 400;
$font-weight-bold: 600;

$font-family-header: 'Varela Round', helvetica, arial, sans-serif;
$font-family-body: 'Open Sans', helvetica, arial, sans-serif;

$image-transition: ease-in-out 0.1s all;
