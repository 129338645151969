@import 'variables';

.masthead {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $masthead-height;
  padding: 10px;
  border-bottom: 1px solid $grey-light;
  background-color: rgba(255, 255, 255, 0.98);
  z-index: 1000;
  display: table;

  @media all and (min-width: 768px) {
    padding: 10px 15px;
  }

  .main-header,
  .main-nav {
    display: table-cell;
    vertical-align: middle;
  }
}

.main-header {
  a {
    color: $primary;
    line-height: 1em;

    &:hover {
      .logo {
        transform: scale(1.05, 1.05);
      }

      .logo-small {
        transform: scale(1.1, 1.1);
      }
    }
  }

  .logo,
  .logo-small {
    transition: $image-transition;
    vertical-align: middle;
    height: 40px;
  }

  .logo-small {
    display: inline-block;
  }

  .logo {
    display: none;
  }

  @media all and (min-width: 768px) {
    .logo-small {
      display: none;
    }

    .logo {
      display: inline-block;
    }
  }
}

.main-nav {
  margin: 0 auto;
  text-align: right;
  font-size: 18px;

  ul {
    margin: 0;
  }

  li {
    display: inline;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    display: inline-block;
    font-family: $font-family-header;
    text-decoration: none;

    &.active {
      color: $primary;

      span::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: block;
        margin-top: 4px;
        border-bottom: 2px solid $primary;
      }
    }

    .text {
      position: relative;
      display: none;

      @media all and (min-width: 480px) {
        display: inline;
      }
    }

    .text,
    svg {
      vertical-align: middle;
    }
  }

  svg {
    fill: $tertiary;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    display: inline-block;
  }

  a.active svg {
    fill: $primary;
  }
}
