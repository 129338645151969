@import 'variables';

.module {
  margin: 25px 0;

  &::before,
  &::after {
    content: '';
    display: table;
    clear: both;
  }

  .column {
    margin-bottom: 25px;
  }
}

.module-heading {
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 2px solid $primary;
}
