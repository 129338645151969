@import 'variables';

.about-hero {
  width: 100%;
  margin: 0 auto;
}

.hero {
  position: relative;
  color: $white;
  text-align: center;
  background-image: url('../img/hero.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  h1 {
    color: $white;
    font-size: 28px;
  }

  p {
    font-size: 20px;
  }

  @media all and (min-width: 768px) {
    text-align: left;

    h1 {
      font-size: 48px;
    }

    p {
      font-size: 24px;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(darken($primary, 5%), 0.7);
    z-index: 0;
  }

  .hero-content {
    position: relative;
    z-index: 1;
    margin: 25% 0;
  }
}
