@import 'variables';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: $black;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.header {
  color: $primary;
  text-shadow: 0 0 1px $white;

  svg {
    fill: $primary;
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
  margin: 16px 0;
  padding: 0;
  list-style-type: none;
}

h1 {
  margin: 25px 0;

  svg {
    width: 24px;
    height: 24px;
  }
}

abbr {
  cursor: help;
}

a {
  color: $tertiary;
  text-decoration: none;
  transition: background-color, border-color, color, fill;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;

  &:hover {
    text-decoration: underline;
  }
}

iframe {
  display: block;
  max-width: 100%;
  margin: 16px 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  border: none;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 20px;
}
