@import 'variables';

.social {
  display: block;
  list-style: none;
  margin: 12px 0;
  padding: 0;

  &::before,
  &::after {
    // Clearfix for float
    content: '';
    display: table;
    clear: both;
  }

  li {
    display: inline-block;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    display: block;
    width: $social-icon-size;
    height: $social-icon-size;
    fill: $white;
  }
}

a {
  svg {
    transition: $image-transition;
  }

  &:hover svg {
    transform: scale(1.2, 1.2);
  }
}

.meetup-link {
  color: $meetup-color;

  svg {
    fill: $meetup-color;
    margin-right: 0;
  }
}

h2 .meetup-link svg {
  width: 24px;
  height: 24px;
}

h3 .meetup-link svg {
  width: 18px;
  height: 18px;
  margin-bottom: -2px;
}

p .meetup-link svg {
  width: 18px;
  height: 18px;
  margin-left: 2px;
  margin-bottom: -3px;
}
