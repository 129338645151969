@import 'variables';

.footer {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: $primary-dark;
  color: $grey-light;
}

.links {
  li {
    display: inline;

    &::after {
      content: " •";
      margin: 0 8px;
      color: $grey-light;
    }

    &:last-child::after {
      content: "";
    }
  }
}

.credits,
.credits a {
  font-size: 12px;
}
